// src/components/Home.js

import React, { useEffect } from 'react';
import logo from '../img/lmcloud-logoblk.png'; // Adjust the path as necessary

const Home = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-J68BC2MF06";
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-J68BC2MF06');
  }, []);

  return (
    <div className="container-fluid">
      <main className="row hero-section">
        <div className="col-12 col-md-8 hero-content">
          <h1><b>BOOSTING YOUR</b></h1>
          <h1><b>CLOUD POTENTIAL</b></h1>
          <br />
          <h3>A new perspective in cloud consulting.</h3>
          <br />
          <a href="/contact" className="btn btn-primary">ENQUIRE NOW</a>
        </div>
        <div className="col-12 col-md-4 hero-logo">
          <img src={logo} alt="LM Cloud Consulting Logo" className="img-fluid" />
        </div>
      </main>
    </div>
  );
};
  
export default Home;
