import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_qqe593q',
        'template_tawl75r',
        form.current,
        'm8BgVM88VIr8iXzH1'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent successfully!');
        },
        (error) => {
          console.log(error.text);
          alert('An error occurred, please try again later.');
        }
      );
  };

  return (
    <div className="container-fluid">
      <main className="row hero-section">
        <div className="col-lg-6">
          <h1 className="display-3 fw-bold mb-3">Contact Us</h1>
          <br />
          <br />
          <h3>
            Get in touch with us to discuss how we can help your business thrive
            with cloud solutions.
          </h3>
          <form id="contact-form" ref={form} onSubmit={sendEmail}>
            <div className="form-group text-white">
              <label htmlFor="from_name">Name</label>
              <input
                type="text"
                className="form-control"
                id="from_name"
                name="from_name"
                required
              />
            </div>
            <div className="form-group text-white">
              <label htmlFor="from_email">Email</label>
              <input
                type="email"
                className="form-control"
                id="from_email"
                name="from_email"
                required
              />
            </div>
            <div className="form-group text-white">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3"
                required
              ></textarea>
            </div>
            <br />
            <button
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </form>
        </div>

        <div className="col-lg-6">
          <h3>
            <b>Email:</b> info@lmcloud-consulting.com
          </h3>
          <h3>
            <b>Address:</b>
            <br />
            71-75, Shelton Street,
            <br />
            Covent Garden,
            <br />
            London,
            <br />
            WC2H 9JQ, UNITED KINGDOM
          </h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.907722759788!2d-0.12612668763512103!3d51.51490891003691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccaaa0b0b7%3A0xbe144a0754857ae1!2s71-75%20Shelton%20St%2C%20London%20WC2H%209JQ!5e0!3m2!1sen!2suk!4v1724011951989!5m2!1sen!2suk"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </main>
    </div>
  );
};

export default Contact;
