// src/components/Booking.js

import React from 'react';

const Booking = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <h1>Book A Meeting With The LM Cloud Team</h1>
      </div>
      <br /><br /><br />
      <div style={{ width: '100%', height: '100%' }}>
        <iframe 
          src="https://outlook.office365.com/owa/calendar/ScheduleTimeWithLMCloud@lm-cloud.co.uk/bookings/"
          width="100%" 
          height="1600px" 
          style={{ border: 0, overflow: 'auto' }}
          title="Booking Calendar"
        ></iframe>
      </div>
    </div>
  );
};

export default Booking;
